<template>
  <v-theme-provider dark>
    <section id="hero-cont">
      <v-img
        :height="$vuetify.breakpoint.mdAndUp ? 550 : 225"
        :gradient="gradient"
        :lazy-src="lazySrc"
        :src="src"
        color="#45516b"
        flat
        max-width="100%"
        tile
      >
        <v-container class="fill-height px-4 py-12">
          <v-responsive
            class="d-flex align-center"
            height="100%"
            width="100%"
            align="center"
          >
            <base-img
              :src="require('@/assets/logo_only_circle.png')"
              contain
              max-width="8%"
              min-height="8%"
              width="100%"
            />
            <base-heading
              :title="$t('HeroCont.title')"
            />
            <base-body />
          </v-responsive>
        </v-container>
      </v-img>
    </section>
  </v-theme-provider>
</template>

<script>
  // Components
  import {
    HexToRGBA,
    RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'

  export default {
    name: 'SectionHeroCont',

    metaInfo () {
      return {
        changed: meta => (this.title = meta.titleChunk.toUpperCase()),
      }
    },

    provide: {
      heading: { align: 'center' },
    },
    computed: {
      gradient () {
        const color = `${this.$vuetify.theme.themes.light.secondary}CC`
        const overlay = RGBAtoCSS(HexToRGBA(color))

        return `to top, ${overlay}, ${overlay}`
      },
      src () {
        return this.$route.meta.src
      },
      lazySrc () {
        return this.$route.meta.lazySrc
      },
      items () {
        return [
          { text: 'HOME', to: '/' },
          { text: this.title },
        ]
      },
    },
  }
</script>
